import * as React from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  WithStyles,
  withStyles,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  createStyles
} from "@material-ui/core";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import moment from "moment";
import _ from "lodash";
import GradientCardHeader, { HeaderButton } from "../common/GradientCardHeader";
import CenteredProgress from "../common/CenteredProgress";
import { TableHead, TableHeadCell } from "../common/GradientTableHeader";
import Priority from "../compliance/Priority";
import Icon from "../common/Icon";
import { colors } from "../../Theme";
import arcadiaLogoWhite from "../../images/arcadia-logo-white.png";
import { Arcadia } from "../../links";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
  MyDocuments,
  MyDocuments_documents_items
} from "../../graphql/MyDocuments";

export const MY_DOCUMENTS_QUERY = gql`
  query MyDocuments {
    documents {
      items {
        id
        type
        title
        category
        notes
        status
        due
        created
        priority
        file
        owner {
          name
          title
          avatar {
            url
          }
        }
      }
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    table: {},
    link: {
      textDecoration: "none",
      color: theme.palette.grey[800],
      fontWeight: 600,
      "&:hover": {
        textDecoration: "underline"
      }
    },
    Closed: {
      opacity: 0.5
    },
    type: {
      width: 50,
      paddingRight: 0,
      color: colors.primary
    },
    file: {
      width: 50,
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: 20,
      textAlign: "center"
    },
    thinColumn: {
      width: 50,
      paddingRight: 0
    },
    secondaryText: {
      display: "block",
      opacity: 0.5,
      fontSize: 12,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap" as "nowrap",
      overflow: "hidden",
      maxWidth: 250
    },
    avatar: {
      "&>img": {
        objectPosition: "top"
      }
    },
    arcadiaLogo: {
      height: 32,
      marginTop: -theme.spacing(1),
      marginLeft: -theme.spacing(1),
      marginBottom: -theme.spacing(1)
    }
  });

const dueDateStyle = (dueDate: string) => {
  return moment(dueDate).isBefore(moment()) ? { color: "#ff4d51" } : {};
};

const TypeIcon: React.SFC<{ type: string }> = ({ type }) => {
  switch (type) {
    case "compliance":
      return <Icon icon="compliance" />;
    case "marketing":
      return <Icon icon="announce" />;
    case "incident":
      return <Icon icon="cone" />;
    case "disclosure":
      return <Icon icon="disclosure" />;
    case "change":
      return <Icon icon="change" />;
    default:
      return null;
  }
};

interface Props extends WithStyles<typeof styles> {
  linkToArcadia?: boolean;
}

class Compliance extends React.Component<Props> {
  render() {
    const { classes, linkToArcadia } = this.props;
    return (
      <Query<MyDocuments> query={MY_DOCUMENTS_QUERY}>
        {({ loading, data }) => {
          const documents = _.get(data, "documents.items") || [];
          const showSpinner = loading && documents.length === 0;

          return (
            <React.Fragment>
              <Card>
                <GradientCardHeader
                  title="Security and Compliance"
                  action={
                    linkToArcadia && (
                      <HeaderButton component={Arcadia}>
                        <img
                          className={classes.arcadiaLogo}
                          src={arcadiaLogoWhite}
                        />{" "}
                        Launch Arcadia
                      </HeaderButton>
                    )
                  }
                />
                <Table className={classes.table} size={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell className={classes.thinColumn}>
                        Type
                      </TableHeadCell>
                      <TableHeadCell>Title</TableHeadCell>
                      <TableHeadCell>File</TableHeadCell>
                      <TableHeadCell>Category</TableHeadCell>
                      <TableHeadCell>Status</TableHeadCell>
                      <TableHeadCell>Priority</TableHeadCell>
                      <TableHeadCell>Due</TableHeadCell>
                      <TableHeadCell>Owner</TableHeadCell>
                      <TableHeadCell>Owner Title</TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents.map((document: MyDocuments_documents_items) => (
                      <TableRow
                        key={document.id}
                        hover
                        className={
                          classes[document.status as keyof Props["classes"]]
                        }
                      >
                        <TableCell className={classes.type}>
                          <TypeIcon type={document.type} />
                        </TableCell>
                        <TableCell>
                          <div>{document.title}</div>
                          <div className={classes.secondaryText}>
                            {document.notes}
                          </div>
                        </TableCell>
                        <TableCell className={classes.file}>
                          {document.file && (
                            <IconButton href={document.file} target="_blank">
                              <AttachFileIcon color="primary" />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell>{document.category}</TableCell>
                        <TableCell>{document.status}</TableCell>
                        <TableCell>
                          <Priority status={document.priority} />
                        </TableCell>
                        <TableCell style={dueDateStyle(document.due)}>
                          {moment(document.due).format("MMM D, YYYY")}
                        </TableCell>
                        <TableCell>
                          <Avatar
                            title={document.owner.name}
                            src={document.owner.avatar.url}
                            className={classes.avatar}
                          />
                        </TableCell>
                        <TableCell>{document.owner.title}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {showSpinner && <CenteredProgress />}
                <CardContent>
                  <Typography variant="caption">
                    This information is confidential, do not disclose this to
                    unauthorised persons.
                  </Typography>
                </CardContent>
              </Card>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(Compliance);
