import PrivateRoute from "../components/auth/PrivateRoute";
import React, { Component } from "react";
import Prototyping from "../components/apps/Prototyping";
import PaddedLayout from "../components/common/PaddedLayout";
import PageTitleBar from "../components/common/header/PageTitleBar";

class CodeSamplesPage extends Component {
  render() {
    return (
      <React.Fragment>
        <PageTitleBar title="Code Samples" />
        <PaddedLayout>
          <Prototyping />
        </PaddedLayout>
      </React.Fragment>
    );
  }
}

export default () => <PrivateRoute component={CodeSamplesPage} />;
