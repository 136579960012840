import React from "react";
import { withStyles, WithStyles, Theme } from "@material-ui/core";
import { colors } from "../../Theme";
import cx from "classnames";

const styles = (theme: Theme) => ({
  root: {
    fontWeight: 600,
    textTransform: "capitalize" as "capitalize"
  },
  high: {
    color: colors.orange
  },
  medium: {
    color: theme.palette.grey["800"]
  },
  low: {
    color: theme.palette.grey["400"]
  }
});

interface Props extends WithStyles<typeof styles> {
  status: string;
}

const Priority: React.SFC<Props> = ({ classes, status }) => {
  return (
    <span className={cx(classes.root, (classes as any)[status.toLowerCase()])}>
      {status}
    </span>
  );
};

export default withStyles(styles)(Priority);
