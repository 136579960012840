import NavLink from "gatsby-link";
import React from "react";

export const Arcadia = React.forwardRef((props: any, ref: any) => (
  <NavLink
    {...props}
    to="/apply"
    state={{ unavailableFeature: true }}
    ref={ref}
  />
));
