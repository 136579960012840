import React, { Component } from "react";
import {
  createStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import cx from "classnames";
import Highlight, { defaultProps } from "prism-react-renderer";
import { CodeSample } from "./CodeSamples";

const styles = (theme: Theme) =>
  createStyles({
    embed: {
      textAlign: "left",
      opacity: 1,
      transition: "opacity 0.5s",
      marginLeft: "auto",
      marginRight: "auto"
    },
    hidden: {
      minHeight: 600,
      opacity: 0
    },
    tab: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      minWidth: 0,
      minHeight: 0,
      borderRadius: theme.shape.borderRadius,
      marginLeft: theme.spacing(1)
    },
    tabs: {
      marginBottom: theme.spacing(1)
    },
    selectedTab: {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main
    },
    tabIndicator: {
      display: "none"
    }
  });

const prismTheme = {
  plain: {
    backgroundColor: "#000",
    color: "#fff"
  },
  styles: [
    {
      types: ["comment", "prolog", "doctype", "cdata", "punctuation"],
      style: {
        color: "#888888"
      }
    },
    {
      types: [
        "boolean",
        "string",
        "entity",
        "url",
        "attr-value",
        "keyword",
        "control",
        "directive",
        "unit",
        "statement",
        "regex",
        "at-rule",
        "placeholder",
        "variable"
      ],
      style: {
        color: "#84d055"
      }
    }
  ]
};

interface Props extends WithStyles<typeof styles> {
  id: "default" | string;
  sources: CodeSample[];
  title: string;
}

interface State {
  activeSandboxTab: number;
}

export class APISandbox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { activeSandboxTab: 0 };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.sources !== this.props.sources) {
      this.setState({ activeSandboxTab: 0 });
    }
  }

  render() {
    const { classes, sources } = this.props;
    const { activeSandboxTab } = this.state;

    return (
      <React.Fragment>
        <div className={cx(classes.embed)}>
          <Typography variant="h6" paragraph>
            {this.props.title}
          </Typography>

          <Tabs
            value={Math.min(activeSandboxTab, sources.length - 1)}
            onChange={this.sandboxTabChange}
            className={classes.tabs}
            classes={{ indicator: classes.tabIndicator }}
          >
            {sources.map((sample, i) => (
              <Tab
                key={i}
                value={i}
                className={classes.tab}
                classes={{ selected: classes.selectedTab }}
                label={sample.title}
              />
            ))}
          </Tabs>

          <Highlight
            {...defaultProps}
            code={this.getActiveSource()}
            language="jsx"
            theme={prismTheme}
          >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
                {tokens.map((line, i) => (
                  <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                ))}
              </pre>
            )}
          </Highlight>
        </div>
      </React.Fragment>
    );
  }

  private getActiveSource = () => {
    const { sources } = this.props;
    const { activeSandboxTab } = this.state;
    return sources[activeSandboxTab] ? sources[activeSandboxTab].source : "";
  };

  private sandboxTabChange = (
    _: React.ChangeEvent<{}>,
    value: State["activeSandboxTab"]
  ) => {
    this.setState({ activeSandboxTab: value });
  };
}

export default withStyles(styles)(APISandbox);
