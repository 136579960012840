export interface CodeSample {
  title: string;
  source: string;
}

const codeSample = (
  sample: string
) => `// This is a code example using Sargon's node API client library.
// To get started, install the api client library into your existing
// node.js project using the following command:
// npm install @sargon/api-client
const { SargonApiClient } = require("@sargon/api-client");

// To securely authenticate to the API, you will need to use your API keys:
// Client ID and Secret. In this example, we have securely loaded the API
// keys into the environment variable.
const client = new SargonApiClient({
    clientId: process.env.CLIENT_ID,
    clientSecret: process.env.CLIENT_SECRET,
    accessTokenUri: "https://df7d07ee3f2f4d5a941642a0110aba41.auth.ap-southeast-2.amazoncognito.com",
    basePath: "https://api.impactcrm.com.au",
    version: "v1"
});

${sample}
`;

export const listMembersSource: CodeSample = {
  title: "List Members",
  source: codeSample(`return await client.members().getMembers({
  q: "Smith" // Try "Collier"
});
`)
};

export const createMemberSource = (): CodeSample => ({
  title: "Create Member",
  source: codeSample(`const memberDetails = {
  "givenName": "Alberto",
  "familyName": "Lebsak",
  "birthDate": "1979-05-30",
  "phoneMobile": "+61412345678",
  "email":"alberto${new Date().getTime()}@test.com",
  "taxId":"123456782",
  "addressResidential": {
    "line1":"24 Madeup St",
    "suburb": "Sydney",
    "state": "NSW",
    "postcode": "2000",
    "countryCode": "AU"
  }
}

const member = await client.members().createMember(memberDetails);

console.log("Created member details:", member);
console.log("Member access token (copy this for use with other code samples):");
member.data.token.id_token;
`)
});

export const getBalanceSource: CodeSample = {
  title: "Get Fund Balance",
  source: codeSample(`return await client.reports().getBalance({
  date: "2018-06-01,today"
});
`)
};

export const fundSearch: CodeSample = {
  title: "Fund Search",
  source: codeSample(`// For member functions you'll need a member access token.
// The simplest way to get one is to copy the access token
// from the output of the "Create Member" code sample.
const accessToken = "[Replace with member access token]";


// You have to perform OTP verification to have a valid OTP sesison before performing fund search
// const otpApi = client.otp({ accessToken });

// 1. Request OTP via SMS
// const response = await otpApi.requestMemberOtp("me");
// console.log(JSON.stringify(response));

// 2. Retrieve OTP code from SMS

// 3. Verify OTP
// const response = await otpApi.verifyMemberOtp("me", {xSuperOTP: "[Replace with OTP code]"});
// console.log(JSON.stringify(response));


// Create instance of API using member access token
const fundSearchRolloverApi = client.fundSearchRollover({ accessToken });

// Perform the fund search
const response = await fundSearchRolloverApi.performFundSearch("me");
const { id: resultId } = response.data;
console.log('Fund search result id (copy this for use with other code samples):', resultId);

// Fetch the results
// Fund search is an async operation so we use this function
// to poll for the results until they are ready
async function getResults(fundSearchRolloverApi, resultId, count) {
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  if (count > 12) return "Timed out waiting for results";
  const results = await fundSearchRolloverApi.listFundSearchResult("me", resultId);
  if (results === null) {
    console.log("Results not ready, waiting...")
    await sleep(5000);
    return getResults(fundSearchRolloverApi, resultId, count + 1);
  }
  return results;
}
console.log('Loading results');
await getResults(fundSearchRolloverApi, resultId, 1);
`)
};

export const fundRollover: CodeSample = {
  title: "Consent to Fund Rollover",
  source: codeSample(`// For member functions you'll need a member access token.
// The simplest way to get one is to copy the access token
// from the output of the "Create Member" code sample.
const accessToken = "[Replace with member access token]";

// Fund search result id. Copy this from the output of the "Fund Search" code sample.
const fundSearchId = "[Replace with fund search result id]";

// For compliance purposes, please record the contents of the Terms and Conditions
// as agreed to by the user at the time of consenting to the rollover. This can be
// in the form of a file (as below) or a string (change contentType to text/plain).

// Using fs.createReadStream(...) will populate content type and file name automatically.
const consentTermsFile = Buffer.from("binary data ...");
consentTermsFile.options = {
  filename: "test.pdf",
  contentType: "application/pdf"
};

// Perform consent to rollover
await client
    .fundSearchRollover({ accessToken })
    .consentToRollover(
      fundSearchId,
      consentTermsFile
    );
`)
};
